import React, { useEffect, Fragment, useState } from "react";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import {
  activeDetailsState,
  voteStatusState,
  votingResultsState,
  motionStatusState,
  activeMotionState,
  topicSpeakersState,
  motionListState,
  motionedByState,
} from "../../atoms/session.atom";
import { userState } from "../../atoms/user.atom";
import { speakerModalState, triggerNextTopic } from "../../atoms/globals.atom";
import {
  soloSettingsState,
  topicSectionState,
} from "../../atoms/settings.atom";

import { Menu, Transition } from "@headlessui/react";
import { PlusIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";

// components
import VoteModal from "../Modals/VoteModal";
import InlineVotingResults from "./InlineVotingResults";
import TopicSpeaker from "./TopicSpeaker";
import SpeakerModal from "../Modals/InMeetingSpeaker";
import MotionModal from "./MotionModal";
import MotionButton from "./MotionButton";
import SpeakerLiveModal from "../Modals/SpeakerLiveModal";

function TopicDetails({ sendEvent }) {
  const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);
  const [motionModal, setMotionModal] = useState(false);
  const [motionType, setMotionType] = useState();
  const [addSpeakerModal, setAddSpeakerModal] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [pdfWidth, setPdfWidth] = useState(775);
  const [section, setSection] = useRecoilState(topicSectionState);
  const activeDetails = useRecoilValue(activeDetailsState);
  const motionedBy = useRecoilValue(motionedByState);
  const motionStatus = useRecoilValue(motionStatusState);
  const activeMotion = useRecoilValue(activeMotionState);
  const motionList = useRecoilValue(motionListState);
  const voteStatus = useRecoilValue(voteStatusState);
  const user = useRecoilValue(userState);
  const votingResults = useRecoilValue(votingResultsState);
  const topicSpeakers = useRecoilValue(topicSpeakersState);
  const speakerModal = useRecoilValue(speakerModalState);
  const soloSettings = useRecoilValue(soloSettingsState);
  const setTriggerNext = useSetRecoilState(triggerNextTopic);

  useEffect(() => {
    if (activeDetails) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
          userAgent
        );
      setMobile(isTablet);
      if (window?.innerWidth < 1536) {
        setPdfWidth(575);
      }
    }
  }, [activeDetails]);

  useEffect(() => {
    if (Object.entries(votingResults).length > 0) {
      if (user.managerRole && soloSettings.autoNextTopic) {
        setTimeout(() => {
          setTriggerNext(true);
        }, soloSettings.memberResultsTimer * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [votingResults]);

  useEffect(() => {
    if (motionedBy.length > 0) {
      setMotionModal(true);
    }
  }, [motionedBy]);

  useEffect(() => {
    if (voteStatus) {
      setMotionModal(false);
    }
  }, [voteStatus]);

  const handleVoteStart = () => {
    sendEvent("voteStarted", {
      motion: activeMotion,
      topic: activeDetails,
    });
  };

  const handleNewMotion = (value) => {
    setMotionType(value);
    setMotionModal(true);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onDocumentLoadError(error) {
    console.error(error);
    console.log(error.message);
  }

  if (!activeDetails) {
    return (
      <div className="flex h-full w-full justify-start mx-2">
        <div className="flex flex-col h-full w-full justify-start mx-2 divide-y divide-gray-200">
          <p className="px-2 mt-2 text-base xl:text-2xl text-gray-700 leading-relaxed tracking-wide">
            No topic has been chosen yet.
          </p>
        </div>
      </div>
    );
  }

  if (voteStatus) {
    return <VoteModal sendEvent={sendEvent} />;
  }

  if (user?.managerRole && votingResults.votes) {
    return <InlineVotingResults />;
  }

  return (
    <div className="relative h-full w-full justify-start mx-0 2xl:mx-2 overflow-hidden">
      <div className="mt-2 items-center flex flex-nowrap justify-between w-full px-2">
        <h1 className="leading-6 text-gray-900 text-sm xl:text-base 2xl:text-2xl font-semibold">
          Item: {activeDetails?.itemNumber}
        </h1>
        {activeDetails?.fileUrl.length > 0 && section === 1 && (
          <div className="">
            <button
              className="px-2 py-1 mr-2 2xl:mr-0.5 text-black hover:text-blue-600"
              onClick={() => {
                setPdfWidth(pdfWidth - 35);
              }}
            >
              <AiOutlineZoomOut className="h-5 w-5 2xl:h-7 2xl:w-7" />
            </button>
            <button
              className="px-2 py-1 text-black hover:text-blue-600"
              onClick={() => {
                setPdfWidth(pdfWidth + 35);
              }}
            >
              <AiOutlineZoomIn className="h-5 w-5 2xl:h-7 2xl:w-7" />
            </button>
          </div>
        )}
      </div>
      {/* Start InLine Nav */}
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-4 2xl:space-x-12 items-center">
            <div
              className={`whitespace-nowrap py-2 2xl:py-4 px-1 border-b-2 font-medium text-sm xl:text-base 2xl:text-lg cursor-pointer
                              ${
                                section === 0
                                  ? "border-purple-500 text-purple-600 "
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 "
                              }
                              `}
              onClick={() => setSection(0)}
            >
              Details
            </div>
            {activeDetails.fileUrl && (
              <div
                className={`whitespace-nowrap py-2 2xl:py-4 px-1 border-b-2 font-medium text-sm xl:text-base 2xl:text-lg cursor-pointer
                              ${
                                section === 1
                                  ? "border-purple-500 text-purple-600 "
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 "
                              }
                              ${
                                activeDetails.fileUrl.length === 0
                                  ? "opacity-0"
                                  : "opacity-100"
                              }
                            `}
                onClick={() => setSection(1)}
              >
                Document
              </div>
            )}

            <div
              className={`whitespace-nowrap py-2 2xl:py-4 px-1 border-b-2 font-medium text-sm xl:text-base 2xl:text-lg cursor-pointer
                              ${
                                section === 2
                                  ? "border-purple-500 text-purple-600 "
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 "
                              }
                            `}
              onClick={() => setSection(2)}
            >
              Speakers
            </div>
            {user?.managerRole && (
              <>
                <div />
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex items-center justify-center w-full px-4 py-2 text-sm xl:text-base 2xl:text-lg font-medium text-white rounded-md bg-gradient-to-r from-green-500 to-green-600 hover:from-green-400 hover:to-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                      Add Motion
                      <PlusIcon
                        className="hidden 2xl:block w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-40 2xl:w-52 origin-top-right mt-1 px-2 py-2 bg-gray-200 divide-y divide-gray-300 rounded-md shadow-2xl focus:outline-none border border-gray-200">
                      {motionList?.map((item, index) => (
                        <Menu.Item
                          className="text-sm xl:text-base 2xl:text-lg py-2 px-2 cursor-pointer hover:bg-white rounded-md"
                          key={index}
                          onClick={() => handleNewMotion(item)}
                        >
                          <p>{item}</p>
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </>
            )}
            {!user?.managerRole &&
              user?.votingRole &&
              soloSettings.motioning && <MotionButton sendEvent={sendEvent} />}
          </nav>
        </div>
      </div>
      {/* End Inline Nav */}
      {/* Start Content */}
      {section === 0 && (
        <div className="overflow-y-auto">
          <p className="px-2 mt-2 text-sm xl:text-lg 2xl:text-2xl text-gray-700 leading-relaxed tracking-wide">
            {activeDetails?.more}
          </p>
          {(user?.managerRole || user?.adminRole) &&
            activeDetails.notes?.length > 0 && (
              <p className="px-2 mt-2 text-sm xl:text-lg 2xl:text-2xl text-gray-800 leading-relaxed tracking-wide bg-green-200">
                {activeDetails.notes}
              </p>
            )}
        </div>
      )}
      {section === 1 && (
        <div
          className="relative w-full h-full overflow-y-auto my-2 flex justify-center overscroll-contain"
          style={{ maxHeight: "87%" }}
        >
          {activeDetails.fileUrl.length > 0 && !mobile && (
            <Document
              file={`${activeDetails.fileUrl}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              externalLinkTarget="_blank"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={pdfWidth}
                />
              ))}
            </Document>
          )}
          {activeDetails.fileUrl.length > 0 && mobile && (
            <Document
              file={`${activeDetails.fileUrl}`}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              externalLinkTarget="_blank"
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={pdfWidth}
                />
              ))}
            </Document>
          )}
          {/* {activeDetails.fileUrl.length > 0 && !mobile && (
            <iframe
              src={`${activeDetails.fileUrl}#view=fitH&statusbar=1&toolbar=0`}
              className="w-full h-full"
              title="document"
            ></iframe>
          )} */}
          {/* {activeDetails.fileUrl.length > 0 && mobile && (
            <object
              aria-label="test"
              width="900"
              height="900"
              data={`https://docs.google.com/gview?embedded=true&url=${activeDetails.fileUrl}`}
            ></object>
          )} */}
        </div>
      )}

      {section === 2 && (
        <>
          {user?.managerRole && (
            <div
              className="my-2 w-full py-2 px-2 flex items-center bg-green-400 rounded-md text-black text-xs lg:text-sm xl:text-base font-bold cursor-pointer hover:bg-opacity-70"
              onClick={() => setAddSpeakerModal(true)}
            >
              <PlusIcon
                className="w-5 2xl:h-6 2xl:w-6 h-5 text-black hover:text-white-100 mr-2 lg:mr-4"
                aria-hidden="true"
              />
              <p>Add Speaker Information</p>
            </div>
          )}
          <div className="h-full space-y-4 px-2 my-4 2xl:px-10 overflow-y-auto pb-48">
            {topicSpeakers.map((speaker, index) => {
              let attend;
              speaker.attendOrCall === "A" ? (attend = true) : (attend = false);
              return (
                <TopicSpeaker
                  user={user}
                  speaker={speaker}
                  index={index}
                  attend={attend}
                  key={index}
                />
              );
            })}
          </div>
        </>
      )}
      {user?.managerRole && (
        <MotionModal
          type={motionType}
          visible={motionModal}
          show={setMotionModal}
          sendEvent={sendEvent}
          motionedBy={motionedBy}
        />
      )}
      <SpeakerModal sendEvent={sendEvent} visible={speakerModal} />
      {addSpeakerModal && (
        <SpeakerLiveModal
          sendEvent={sendEvent}
          topic={activeDetails}
          visible={setAddSpeakerModal}
        />
      )}
      {/* End Content */}
      {activeMotion && (
        <div className="absolute inset-x-0 bottom-0 rounded-md bg-blue-400 p-2 mb-2">
          <div className="block space-y-2 w-full px-4">
            <h3 className="text-base xl:text-lg 2xl:text-xl font-medium text-gray-900 text-center">
              {motionStatus} Motion in Progress...
            </h3>
            <div className="mt-2  text-white grid grid-cols-3 gap-6 font-bold items-center">
              {motionedBy?.map((member, index) => {
                return (
                  <div className="block text-center" key={index}>
                    <p className="italic font-light text-black text-sm xl:text-base 2xl:text-lg">
                      {index === 0 ? "Motioned By:" : "Seconded By:"}
                    </p>
                    <p className="text-white text-base xl:text-xl 2xl:text-2xl">
                      {member.firstName} {member.lastName}
                    </p>
                  </div>
                );
              })}
              {(user?.managerRole || user?.adminRole) &&
                motionedBy.length === 2 && (
                  <div>
                    <button
                      className="w-full inline-flex items-center justify-center px-4 2xl:py-4 py-2 text-sm xl:text-base 2xl:text-lg font-medium text-white rounded-md bg-green-600 hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                      onClick={() => handleVoteStart()}
                    >
                      Start Vote
                      <ChevronRightIcon
                        className="hidden 2xl:block w-5 2xl:h-6 2xl:w-6 h-5 ml-2 -mr-1 text-white-200 hover:text-white-100"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TopicDetails;
